import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog.scss"
import Img from "gatsby-image" // add some style if you want!

export default function Blog({ data }: { data: any }) {
  const { edges: posts } = data.allMarkdownRemark
  const { allImageContent } = data

  return (
    <Layout>
      <SEO
        title="Super Productivity – To do list & time tracker for programmers & designers"
        description="Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
      />

      <div className="container">
        <div className="blog-posts">
          {posts
            .filter((post: any) => post.node.frontmatter.title.length > 0)
            .map(({ node: post }: any) => {
              // const img =
              //   post.frontmatter.img &&
              //   allImageContent.edges.find(
              //     (element: any) =>
              //       element.node.fluid.originalName === post.frontmatter.img
              //   )

              return (
                <article className="blog-post-preview" key={post.id}>
                  <div className="txt">
                    <h2>
                      <Link to={post.frontmatter.path}>
                        {post.frontmatter.title}
                      </Link>
                    </h2>
                    <div className="date">{post.frontmatter.date}</div>
                    <p>{post.excerpt}</p>
                    <Link to={post.frontmatter.path}>Read more</Link>
                  </div>

                  {/*{img && (*/}
                  {/*  <Img fluid={img.node.fluid} className="blog-post-img" />*/}
                  {/*)}*/}
                </article>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 400) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`
